import React from "react";
import penguinLogin from "../../assets/Simplified Logo.png";

import { useNavigate } from "react-router";
import styles from "./Header.module.scss";

export function Header() {
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <div>
          <img
            src={penguinLogin}
            alt="penguinLogo"
            className={styles.logo}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className={styles.headerText}>
          <div
            className={styles.button}
            onClick={() => {
              navigate("/");
            }}
          >
            <h2 className={pathName === "/" && styles.bold}>Home</h2>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              navigate("/company");
            }}
          >
            <h2 className={pathName === "/company" && styles.bold}>Company</h2>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              navigate("/services");
            }}
          >
            <h2 className={pathName === "/services" && styles.bold}>Services</h2>
          </div>
        </div>
        <div />
      </header>
    </div>
  );
}
