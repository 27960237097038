import { Provider } from "react-redux";
import "./App.css";
import { PageRoutes } from "./routes";
import  store  from './redux/store';


function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PageRoutes />
      </Provider>
    </div>
  );
}

export default App;
