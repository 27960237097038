import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <div>
      <footer className={styles.footer}>
        <div className={styles.section}>
          <span className={styles.head}>Pengwin Tech Solutions</span>
          <p className={styles.companySubText}>
            Grow, Evolve and Transform
            <br />
            The Strategic Technology Partner for
            <br />
            Your Business.
          </p>
          <a href="mailto:sales@pengwinTech.com" className={styles.email}>
            sales@pengwintech.com
          </a>
        </div>

        <div className={styles.section}>
          <span className={styles.head}>Locations</span>
          <p className={styles.companySubText}>
            <span className={styles.head}>Rajahmundry</span>
            <br />
            303, Mohana Blick, Chandrika Vilasini, Rajahmundry – 533103
            <br />
            <a
              href="tel:939-332-3547"
              className={styles.email}
            >
              +91 94933 23547
            </a>
          </p>
        </div>

        <div className={styles.section}>
          <span className={styles.head}>FullStack Development</span>
          <p className={styles.companySubText}>
            Hybrid Native Mobile Apps
            <br />
            Web Application Development
            <br />
            Support & Maintenance
            <br />
            Industries
          </p>
        </div>
      </footer>
      <div className={styles.copyright}>
        <p>&copy; 2024, Pengwin Tech Solutions - All Rights Reserved.</p>
      </div>
    </div>
  );
}
