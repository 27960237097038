import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
  pathName: ''
};

const pengwinSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    updatePathName: (state, action) => {
      state.pathName = action.payload;
    },
  },
});

export const {
  updatePathName
} = pengwinSlice.actions;
export default pengwinSlice.reducer;
