import styles from "./Services.module.scss";
import serviceLogo from "../../assets/code3.png";

export function Services() {
  return (
    <div>
      <main className={styles.mainContent}>
        <div className={styles.content}>
          <span className={styles.heading}>
            Full Stack Development
            <br />
            <span className={styles.heading2}> What we are good at</span>
          </span>
          <div className={styles.companyRow}>
            <div>
              <span className={styles.subHeading}>
                Businesses now are constantly looking to embrace innovation to
                stay ahead in this competitive marketplace.
              </span>
              <div className={styles.paraStyles}>
                <p className={styles.text}>
                  Companies that have an excellent IT Strategy, with Digitally
                  innovative and transformative solutions to engage with
                  customers or to streamline their businesses are by far leading
                  the Marketplace. It has now become imperative for small or
                  large organizations to quickly adapt digitally disruptive
                  solutions to stay relevant in the Industry.
                </p>
                <p className={styles.text}>
                  <strong>Pengwin</strong> houses some of the most competent and experienced IT
                  Consultants with years of diverse multi-industry exposure on
                  addressing critical business challenges by thoroughly
                  understanding the gaps, analyzing the existing systems in
                  place and overcoming them by creating effective solutions
                  which help businesses streamline their systems and gain a
                  significant competitive edge in the market.
                </p>
              </div>
            </div>
            <div>
              <img src={serviceLogo} alt="" className={styles.serviceLogo} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
