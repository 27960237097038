import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Company } from "./pages/Company/Company";
import { Footer } from "./pages/Footer/Footer";
import { Header } from "./pages/Header/Header";
import { Home } from "./pages/Home/Home";
import { Services } from "./pages/Services/Services";

export function PageRoutes() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/company" element={<Company />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
