import styles from "./Company.module.scss";
import companyLogo from "../../assets/tree.png";

export function Company() {
  return (
    <div>
      <main className={styles.mainContent}>
        <div className={styles.content}>
          <span className={styles.heading}>Who we are & what we do</span>
          <div className={styles.companyRow}>
            <div>
              <span className={styles.subHeading}>
                Prompt | Thoughtful | Effective
              </span>
              <div className={styles.paraStyles}>
                <p className={styles.text}>
                  Pengwin applies technology to digitally transform
                  organizations, with our solution to execution approach, we are
                  enabling enterprise-level companies in the BFSI, Media &
                  Entertainment, Pharma, and Healthcare industries.
                </p>
                <p className={styles.text}>
                  With deep experience in the integration area, we have
                  successfully delivered the most complex solutions with the
                  highest level of quality and meticulous planning.
                </p>
                <p className={styles.text}>
                  Pengwin harnesses the power of the latest technologies to
                  serve customers across the globe and transform their
                  operations with digital and technology models. Our commitment
                  is to deliver 100% scalable applications to guide and keep
                  organizations on a clear path to their digital transformation.
                </p>
              </div>
            </div>
            <div>
              <img src={companyLogo} alt="" className={styles.companyLogo} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
