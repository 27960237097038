import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import codeImage from "../../assets/code.png";
import digital from "../../assets/code4.png";
import fullStack from "../../assets/code1.png";
import nextArrow from "../../assets/rightArrow.png";
import prevArrow from "../../assets/leftArrow.png";
import awsLogo from "../../assets/aws-logo.png";
import googleCloudLogo from "../../assets/google-cloud-logo.png";
import reactLogo from "../../assets/react-logo.png";
import nodejsLogo from "../../assets/node-js-logo.png";
import flutterLogo from "../../assets/flutter-icon.png";
import styles from "./Home.module.scss";

export function Home() {
  const data1 = ["Digital Transformation", "Cloud", "JS Framework"];
  const data2 = [
    "BFSI",
    "Media & Entertainment",
    "Education",
    "Logistics",
    "HealthCare",
  ];
  const data3 = [
    "Modern Apps",
    "Web Applications",
    "JS Frameworks",
    "GraphQL",
    "Node JS",
    "React JS",
  ];
  const partners = [
    { name: "AWS", logo: awsLogo },
    { name: "Google Cloud", logo: googleCloudLogo },
    { name: "react", logo: reactLogo },
    { name: "flutter", logo: flutterLogo },
    { name: "nodejs", logo: nodejsLogo },
  ];
  const whyUs = [
    "Why PengWIN tech?",
    "We have the Winning attitude",
    "Customer is our Partner",
    "Team work and Creativity",
    "Delivering Quality",
  ];

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <img
        src={nextArrow}
        alt="nextArrow"
        className={styles.nextArrow}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <img
        src={prevArrow}
        alt="prevArrow"
        className={styles.prevArrow}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true,
    waitForAnimate: false,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: true,
    useTransform: true,
  };
  
  return (
    <div>
      <main className={styles.mainContent}>
        <Slider {...settings} className={styles.content}>
          {/* Pengwin Tech Solutions */}
          <div>
            <div className={styles.innerWrapper}>
              <div>
                <img
                  src={codeImage}
                  alt=""
                  className={styles.codeImage}
                />
              </div>
              <div className={styles.textData}>
                <h1 className={styles.title}>Pengwin Tech Solutions</h1>
                <div className={styles.text}>
                  {data1.map((item, index) => (
                    <div className={styles.subContent}>
                      <span className={styles.subText}>{item}</span>
                      <div className={styles.dot} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* Digital Transformation */}
          <div>
            <div className={styles.innerWrapper}>
              <div className={styles.textData1}>
                <h1 className={styles.title}>Digital Transformation</h1>
                <div className={styles.text}>
                  {data2.map((item, index) => (
                    <div className={styles.subContent}>
                      <span className={styles.subText}>{item}</span>
                      <div className={styles.dot} />
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <img
                  src={digital}
                  alt=""
                  className={styles.codeImage1}
                />
              </div>
            </div>
          </div>
          {/* Full Stack Development */}
          <div>
            <div className={styles.innerWrapper}>
              <div>
                <img
                  src={fullStack}
                  alt=""
                  className={styles.codeImage}
                />
              </div>
              <div className={styles.textData}>
                <h1 className={styles.title}>Full Stack Development</h1>
                <div className={styles.text}>
                  {data3.map((item, index) => (
                    <div className={styles.subContent}>
                      <span className={styles.subText}>{item}</span>
                      <div className={styles.dot} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <div className={styles.lowerContainer}>
          <div className={styles.lowerLeft}>
            <span className={styles.head}>Pengwin Tech Solutions</span>
            <span className={styles.subHead}>Prompt Thoughtful Effective</span>
          </div>
          <div className={styles.lowerRight}>
            <span className={styles.textPara}>
              We are PengWINS - we are Unique and we are divergent.
            </span>
            <span className={styles.textPara}>
              At PengWIN tech, we value your Ideas, Processes and your Business.
              Our Objective is to deliver the best that enables your business
              growth.
            </span>
            <span className={styles.textPara}>
              With collective experience of leadership of more than 27 years. We
              all understand the value of Execution and through all years we
              have mastered the art of execution.
            </span>
            <span className={styles.textPara}>
              With Big vision and great ambition we entered a small city. We
              started this in Rajahmundry which is considered to be a Tier 3
              city. Our Vision is to show the world that so much of the talent
              comes from somewhere unexpected and We want the world to turn
              towards us.
            </span>
            <span className={styles.textPara}>
              We have great experience in working with Startups, Banking,
              Government organizations and Gaming.
            </span>
            <span className={styles.textPara}>
              We develop services to our customers and constantly deliver our
              products.
            </span>
          </div>
        </div>
        {/* Mission */}
        <div className={styles.mission}>
          <div className={styles.missionHead}>
            <span className={styles.headA}>Mission</span>
          </div>
          <div className={styles.missionText}>
            <span className={styles.subHeadA}>
              Our mission is to be a phenomenal part of our customer’s journey
              by creating long-lasting value through embracing new technologies
              and innovation.
            </span>
          </div>
        </div>
        {/* Technologies Partners */}
        <div className={styles.tech_partners_container}>
          <h2 className={styles.section_title}>Technology Partners</h2>
          <div className={styles.partners_grid}>
            {partners.map((partner, index) => (
              <div key={index} className={styles.partner_card}>
                <img
                  src={partner.logo}
                  alt={`${partner.name} logo`}
                  className={styles.partner_logo}
                />
              </div>
            ))}
          </div>
        </div>
        {/* Why Us */}
        <div className={styles.missionA}>
          <div className={styles.missionHeadA}>
            <span className={styles.headA}>Why Us?</span>
            <span className={styles.subHeadA}>
              We set high personal and company standards, and then consistently
              try to exceed them. <br />
              We believe in Collaboration in working towards the common goal
              with our Clients and Partners.
            </span>
          </div>
          <div className={styles.missionTextA}>
            {whyUs.map((item, index) => (
              <div className={styles.rows}>
                <div className={styles.dot} />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
